export default [
  {
    path: '/orders',
    name: 'orders-index',
    component: () => import('@/views/orders/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.orders',
      breadcrumb: [
        {
          text: 'admin.navigation.orders',
          active: true,
        },
      ],
      resource: 'orders',
      action: 'index',
    },
  },
  {
    path: '/orders/show/:id',
    name: 'orders-show',
    component: () => import('@/views/orders/Show.vue'),
    meta: {
      pageTitle: 'admin.navigation.orders',
      breadcrumb: [
        {
          text: 'admin.navigation.orders',
          to: { name: 'orders-index' },
        },
        {
          text: 'general.view',
          active: true,
        },
      ],
      resource: 'orders',
      action: 'show',
    },
  },

  {
    path: '/reports/:type',
    name: 'reports-show',
    component: () => import('@/views/reports/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.reports',
      breadcrumb: [
        {
          text: 'admin.navigation.reports',
          active: true,
        },
      ],
      resource: 'reports',
      action: 'index',
    },
  },
]
