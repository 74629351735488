export default [
  {
    path: '/fm',
    name: 'file-manager',
    component: () => import('@/views/fm/List'),
    meta: {
      pageTitle: 'admin.navigation.file-manager',
      breadcrumb: [
        {
          text: 'admin.navigation.system',
        },
        {
          text: 'admin.navigation.file-manager',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/system/settings/List'),
    meta: {
      pageTitle: 'admin.navigation.settings',
      breadcrumb: [
        {
          text: 'admin.navigation.system',
        },
        {
          text: 'admin.navigation.settings',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },
  {
    path: '/languages',
    name: 'languages',
    component: () => import('@/views/system/languages/List'),
    meta: {
      pageTitle: 'admin.navigation.languages',
      breadcrumb: [
        {
          text: 'admin.navigation.system',
        },
        {
          text: 'admin.navigation.languages',
          active: true,
        },
      ],
      resource: 'languages',
      action: 'index',
    },
  },
  {
    path: '/translations',
    name: 'translations',
    component: () => import('@/views/system/translations/List'),
    meta: {
      pageTitle: 'admin.navigation.translations',
      breadcrumb: [
        {
          text: 'admin.navigation.system',
        },
        {
          text: 'admin.navigation.translations',
          active: true,
        },
      ],
      resource: 'translations',
      action: 'index',
    },
  },
  {
    path: '/redirects',
    name: 'redirects',
    component: () => import('@/views/system/redirects/List'),
    meta: {
      pageTitle: 'admin.navigation.redirects',
      breadcrumb: [
        {
          text: 'admin.navigation.system',
        },
        {
          text: 'admin.navigation.redirects',
          active: true,
        },
      ],
      resource: 'translations',
      action: 'index',
    },
  },

  {
    path: '/shops',
    name: 'shops-index',
    component: () => import('@/views/system/shops/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.shops',
      breadcrumb: [
        {
          text: 'admin.navigation.shops',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'index',
    },
  },
  {
    path: '/shops/create',
    name: 'shops-store',
    component: () => import('@/views/system/shops/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.shops',
      breadcrumb: [
        {
          text: 'admin.navigation.shops',
          to: { name: 'shops-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'store',
    },
  },
  {
    path: '/shops/edit/:id',
    name: 'shops-update',
    component: () => import('@/views/system/shops/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.shops',
      breadcrumb: [
        {
          text: 'admin.navigation.shops',
          to: { name: 'shops-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'update',
    },
  },

  {
    path: '/statuses',
    name: 'statuses-index',
    component: () => import('@/views/system/statuses/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.statuses',
      breadcrumb: [
        {
          text: 'admin.navigation.statuses',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'index',
    },
  },
  {
    path: '/statuses/create',
    name: 'statuses-store',
    component: () => import('@/views/system/statuses/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.statuses',
      breadcrumb: [
        {
          text: 'admin.navigation.statuses',
          to: { name: 'statuses-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'store',
    },
  },
  {
    path: '/statuses/edit/:id',
    name: 'statuses-update',
    component: () => import('@/views/system/statuses/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.statuses',
      breadcrumb: [
        {
          text: 'admin.navigation.statuses',
          to: { name: 'statuses-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'update',
    },
  },

  {
    path: '/currencies',
    name: 'currencies-index',
    component: () => import('@/views/system/currencies/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.currencies',
      breadcrumb: [
        {
          text: 'admin.navigation.currencies',
          active: true,
        },
      ],
      resource: 'currencies',
      action: 'index',
    },
  },
  {
    path: '/currencies/create',
    name: 'currencies-store',
    component: () => import('@/views/system/currencies/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.currencies',
      breadcrumb: [
        {
          text: 'admin.navigation.currencies',
          to: { name: 'stocks-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'currencies',
      action: 'store',
    },
  },
  {
    path: '/currencies/edit/:id',
    name: 'currencies-update',
    component: () => import('@/views/system/currencies/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.currencies',
      breadcrumb: [
        {
          text: 'admin.navigation.currencies',
          to: { name: 'currencies-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'currencies',
      action: 'update',
    },
  },

  {
    path: '/stocks',
    name: 'stocks-index',
    component: () => import('@/views/system/stocks/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.stocks',
      breadcrumb: [
        {
          text: 'admin.navigation.stocks',
          active: true,
        },
      ],
      resource: 'stocks',
      action: 'index',
    },
  },
  {
    path: '/stocks/create',
    name: 'stocks-store',
    component: () => import('@/views/system/stocks/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.stocks',
      breadcrumb: [
        {
          text: 'admin.navigation.stocks',
          to: { name: 'stocks-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'stocks',
      action: 'store',
    },
  },
  {
    path: '/stocks/edit/:id',
    name: 'stocks-update',
    component: () => import('@/views/system/stocks/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.stocks',
      breadcrumb: [
        {
          text: 'admin.navigation.stocks',
          to: { name: 'stocks-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'stocks',
      action: 'update',
    },
  },

  {
    path: '/payment_methods',
    name: 'payment_methods-index',
    component: () => import('@/views/system/payment_methods/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.payment_methods',
      breadcrumb: [
        {
          text: 'admin.navigation.payment_methods',
          active: true,
        },
      ],
      resource: 'payment_methods',
      action: 'index',
    },
  },
  {
    path: '/payment_methods/create',
    name: 'payment_methods-store',
    component: () => import('@/views/system/payment_methods/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.payment_methods',
      breadcrumb: [
        {
          text: 'admin.navigation.payment_methods',
          to: { name: 'payment_methods-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'payment_methods',
      action: 'store',
    },
  },
  {
    path: '/payment_methods/edit/:id',
    name: 'payment_methods-update',
    component: () => import('@/views/system/payment_methods/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.payment_methods',
      breadcrumb: [
        {
          text: 'admin.navigation.payment_methods',
          to: { name: 'payment_methods-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'payment_methods',
      action: 'update',
    },
  },

  {
    path: '/delivery_methods',
    name: 'delivery_methods-index',
    component: () => import('@/views/system/delivery_methods/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.delivery_methods',
      breadcrumb: [
        {
          text: 'admin.navigation.delivery_methods',
          active: true,
        },
      ],
      resource: 'delivery_methods',
      action: 'index',
    },
  },
  {
    path: '/delivery_methods/create',
    name: 'delivery_methods-store',
    component: () => import('@/views/system/delivery_methods/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.delivery_methods',
      breadcrumb: [
        {
          text: 'admin.navigation.delivery_methods',
          to: { name: 'delivery_methods-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'delivery_methods',
      action: 'store',
    },
  },
  {
    path: '/delivery_methods/edit/:id',
    name: 'delivery_methods-update',
    component: () => import('@/views/system/delivery_methods/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.delivery_methods',
      breadcrumb: [
        {
          text: 'admin.navigation.delivery_methods',
          to: { name: 'delivery_methods-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'delivery_methods',
      action: 'update',
    },
  },

  {
    path: '/seo_templates',
    name: 'seo_templates-index',
    component: () => import('@/views/system/seo_templates/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.seo_templates',
      breadcrumb: [
        {
          text: 'admin.navigation.seo_templates',
          active: true,
        },
      ],
      resource: 'seo',
      action: 'index',
    },
  },
  {
    path: '/seo_templates/edit/:id',
    name: 'seo_templates-update',
    component: () => import('@/views/system/seo_templates/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.seo_templates',
      breadcrumb: [
        {
          text: 'admin.navigation.seo_templates',
          to: { name: 'seo_templates-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'seo',
      action: 'update',
    },
  },
]
