export default [
  {
    path: '/pages',
    name: 'pages-index',
    component: () => import('@/views/pages/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.pages',
      breadcrumb: [
        {
          text: 'admin.navigation.pages',
          active: true,
        },
      ],
      resource: 'pages',
      action: 'index',
    },
  },
  {
    path: '/pages/create',
    name: 'pages-store',
    component: () => import('@/views/pages/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.pages',
      breadcrumb: [
        {
          text: 'admin.navigation.pages',
          to: { name: 'pages-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'pages',
      action: 'store',
    },
  },
  {
    path: '/pages/edit/:id',
    name: 'pages-update',
    component: () => import('@/views/pages/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.pages',
      breadcrumb: [
        {
          text: 'admin.navigation.pages',
          to: { name: 'pages-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'pages',
      action: 'update',
    },
  },

  {
    path: '/pages/catalog/edit',
    name: 'pages-catalog-update',
    component: () => import('@/views/pages/catalog/Edit.vue'),
    meta: {
      pageTitle: 'admin.catalog.title',
      breadcrumb: [
        {
          text: 'admin.navigation.pages',
          to: { name: 'pages-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'pages',
      action: 'update',
    },
  },
  {
    path: '/pages/sale/edit',
    name: 'pages-sale-update',
    component: () => import('@/views/pages/sale/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.sale',
      breadcrumb: [
        {
          text: 'admin.navigation.pages',
          to: { name: 'pages-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'pages',
      action: 'update',
    },
  },
  {
    path: '/pages/novelty/edit',
    name: 'pages-novelty-update',
    component: () => import('@/views/pages/novelty/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.novelty',
      breadcrumb: [
        {
          text: 'admin.navigation.pages',
          to: { name: 'pages-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'pages',
      action: 'update',
    },
  },
  {
    path: '/pages/bestseller/edit',
    name: 'pages-bestseller-update',
    component: () => import('@/views/pages/bestseller/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.bestseller',
      breadcrumb: [
        {
          text: 'admin.navigation.pages',
          to: { name: 'pages-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'pages',
      action: 'update',
    },
  },
  {
    path: '/pages/promotion/edit',
    name: 'pages-promotion-update',
    component: () => import('@/views/pages/promotion/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.promotion',
      breadcrumb: [
        {
          text: 'admin.navigation.pages',
          to: { name: 'pages-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'pages',
      action: 'update',
    },
  },
  {
    path: '/pages/coming_soon/edit',
    name: 'pages-coming_soon-update',
    component: () => import('@/views/pages/coming_soon/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.coming_soon',
      breadcrumb: [
        {
          text: 'admin.navigation.pages',
          to: { name: 'pages-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'pages',
      action: 'update',
    },
  },
]
